import Vue from 'vue'
import VueRouter from 'vue-router'
import Layout from '@/layout'
// import route from '../store/index'
import api from '../api/index'

// 乡村
import localityServeRouter from "./localityServe.js";

// // 仪表盘
import instrumentsPanel from './instrumentsPanel.js';
// // 用户
import uesrlist from './uesrlist.js';
// // 文章 
import articleRouter from './article.js';
// 电商
import ECommerceServiceRouter from './ECommerceService.js';
// 乡村资料设置
import dataSettingRouter from "./dataSetting.js";
// 设置
import setUserRouter from "./setUser.js";
import government from './government.js';
import localService from './localService.js';
import contentManagementRouter from './contentManagement.js';
import examineRouter from './examine.js';
import districtRouter from './district.js';





Vue.use(VueRouter)
export const routes = [
  {
    path: '/redirect',
    // path: '/Layout',
    component: Layout,
    hidden: true,
  },
  {
    path: '/index',
    name: 'index',
    component: () => import('../index.vue')
  },
  {
    path: '/Login',
    name: 'Login',
    component: () => import('../login.vue')
  },

  instrumentsPanel,
  uesrlist,
  localityServeRouter,
  articleRouter,
  government,
  localService,
  ECommerceServiceRouter,
  dataSettingRouter,
  setUserRouter,
  contentManagementRouter,
  examineRouter,
  districtRouter,
  {
    path: '/401',
    component: () => import('@/views/error-page/401'),
    name: 'Page401',
    meta: { title: '401', noCache: true }
  },
  {
    path: '/404',
    component: () => import('@/views/error-page/404'),
    name: 'Page404',
    meta: { title: '404', noCache: true }
  },
  { path: '*', redirect: '/404', hidden: true }
]
const router = new VueRouter({
  routes
})
router.beforeEach((to, form, next) => {
  if (to.meta.title) {
    document.title = to.meta.title

  } else {
    document.title = '本地合美乡村'
  }
  next()

  //接下来写正常的路由守卫就可以了
})
//获取原型对象上的push函数
const originalPush = VueRouter.prototype.push
//修改原型对象中的push方法
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
// 路由拦截

const originalReplace = VueRouter.prototype.replace;

VueRouter.prototype.push = function push(location, onResolve, onReject) {
    if (onResolve || onReject) {
        return originalPush.call(this, location, onResolve, onReject);
    } else {
        return originalPush.call(this, location).catch(err => err);
    }
};

VueRouter.prototype.replace = function replace(location, onResolve, onReject) {
    if (onResolve || onReject) {
        return originalReplace.call(this, location, onResolve, onReject);
    } else {
        return originalReplace.call(this, location).catch(err => err);
    }
};

export default router

