/**
 * api接口的统一出口
*/
import Login from '@/api/Login';
import apply from '@/api/apply';
import ecommerce from '@/api/ecommerce';
import setup from '@/api/setup';
import dataSettings from '@/api/dataSettings';
import government from '@/api/government';
import approval from '@/api/approval';
import userlisttow from '@/api/userlisttow';
import contentManagement from '@/api/contentManagement';
import region from '@/api/region';
import up from '@/api/up';
import serviceArticle from '@/api/serviceArticle';


export default {
    Login,
    apply,
    ecommerce,
    setup,
    dataSettings,
    government,
    approval,
    userlisttow,
    contentManagement,
    region,up,
    serviceArticle
}
